import { ErrorMonitoringService } from './../error-monitoring/error-monitoring.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  DocumentReference,
} from '@angular/fire/compat/firestore';
import { first, map } from 'rxjs/operators';
import { CompanyType } from 'src/app/types';
import { HelperService } from '../helper/helper.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService {
  private companiesRef = 'companies';
  /** contiene la empresa seleccionada para administrar */
  public selectedCompany: CompanyType;

  constructor(
    private angularFirestore: AngularFirestore,
    private storage: AngularFireStorage,
    private errorMonitoringService: ErrorMonitoringService,
    private helperService: HelperService,
  ) {}

  /** guarda una imagen en storage */
  uploadImage(image: any) {
    return new Promise((resolve, reject) => {
      let filePath;
      // Ruta en la que se almacena
      filePath = `recruiter_images/companies/${image.name}`;
      // Almacena la imagen
      this.storage
        .upload(filePath, image)
        .then((snapshot) => {
          // Obtiene la url de descarga
          snapshot.ref.getDownloadURL().then((downloadURL) => {
            resolve(downloadURL);
          });
        })
        .catch((error) => {
          this.errorMonitoringService.sendError(error, 'uploadCompanieImage');
          reject();
        });
    });
  }

  /** crea una empresa */
  createCompany(company: CompanyType): Promise<DocumentReference> {
    return this.angularFirestore
      .collection<any>(this.companiesRef)
      .add(company);
  }

  /** consulta todas las empresas de un usuario */
  getCompaniesByUser(user: string) {
    return this.angularFirestore
      .collection<any>(this.companiesRef, (ref) =>
        ref.where('users', 'array-contains', user),
      )
      .snapshotChanges()
      .pipe(
        map((companies) => {
          return companies.map((company) => {
            const data = company.payload.doc.data();
            const id = company.payload.doc.id;
            return { id, ...data };
          });
        }),
      );
  }

  /** consulta todas las empresas de un usuario en promesa*/
  getCompaniesByUserPromise(user: string) {
    return this.angularFirestore
      .collection<any>(this.companiesRef, (ref) =>
        ref.where('users', 'array-contains', user),
      )
      .snapshotChanges()
      .pipe(
        map((companies) => {
          return companies.map((company) => {
            const data = company.payload.doc.data();
            const id = company.payload.doc.id;
            return { id, ...data };
          });
        }),
        first(),
      )
      .toPromise();
  }

  /** obtiene todas las empresas de la base de datos */
  getAllCompanies() {
    return this.angularFirestore.collection<any>('companies').get();
  }

  /** obtiene todas las empresas de la base de datos */
  getAllCompaniesByState(status: string) {
    return this.angularFirestore
      .collection<any>('companies', (ref) =>
        ref
          .where('status', '==', status)
          .orderBy('registerDate', 'desc')
          .limit(20),
      )
      .snapshotChanges()
      .pipe(
        map((users) => {
          return users.map((user) => {
            const data = user.payload.doc.data();
            const id = user.payload.doc.id;
            return { id, ...data };
          });
        }),
        first(),
      )
      .toPromise();
  }

  /** consulta el detalle de una empresa */
  getCompany(companyId: string) {
    return this.angularFirestore
      .collection<any>(this.companiesRef)
      .doc(companyId)
      .get();
  }

  /** consulta una empresa por su nit */
  getCompanyByIdNumber(companyIdNumber: string) {
    return this.angularFirestore
      .collection<any>(this.companiesRef, (ref) =>
        ref.where('idNumber', '==', companyIdNumber),
      )
      .get();
  }

  /** consulta el detalle de una empresa y observa cambios */
  getCompanySubscription(companyId: string) {
    return this.angularFirestore
      .collection<any>(this.companiesRef)
      .doc(companyId)
      .snapshotChanges();
  }

  /** actualiza una empresa */
  updateCompany(companyId: string, data: any) {
    return this.angularFirestore
      .collection<any>(this.companiesRef)
      .doc(companyId)
      .update(data);
  }

  /** valida si un nit ya existe */
  async validateCompanyId(number: string): Promise<boolean> {
    const findedCompanies = await this.angularFirestore
      .collection<any>(this.companiesRef, (ref) =>
        ref.where('idNumber', '==', number),
      )
      .get()
      .toPromise();
    return Promise.resolve(findedCompanies.empty);
  }

  /** borra los datos de una empresa */
  deleteCompany(companyId: string) {
    return this.angularFirestore
      .collection<any>(this.companiesRef)
      .doc(companyId)
      .delete();
  }

  /** agrega un usuario a una empresa */
  updateCompanyUser(userId: string, userData: any): Promise<void> {
    return this.angularFirestore
      .collection<any>(`companyUsers`)
      .doc(`${userId}`)
      .update(userData);
  }

  /** abre el modal de find you sales */
  openPremiumSalesModal(source: string): void {
    this.helperService.openPremiumModal(
      source,
      {
        mobile: 'assets/imgs/sales/tickets-findyou-mobile.png',
        pc: 'assets/imgs/sales/tickets-findyou-pc.png',
      },
      {
        open: 'openFindYouProSalesModal',
        close: 'closeFindYouProSalesModal',
      },
      [
        {
          analytics: {
            event: 'buyFindYouProSales',
            params: {},
            register: true,
          },
          color: 'primary',
          fill: 'solid',
          title: 'Adquirir créditos',
          type: 'external',
          path: 'https://jobcity.com.co/find-you/?utm_source=app&utm_medium=app&utm_campaign=modal',
        },
        {
          analytics: {
            event: 'contactFindYouProSales',
            params: {},
            register: true,
          },
          color: 'primary',
          fill: 'outline',
          title: 'Hablar con asesor',
          type: 'external',
          path: `https://wa.me/${environment.salesWhatsapp}?text=¡Deseo adquirir tickets de Find You!`,
        },
      ],
      {
        pc_bottom: '35px',
        pc_left: '65px',
        mobile_bottom: '50px',
        mobile_left: '2px',
      },
    );
  }

  /** abre el modal de find you sales */
  openPremiumTicketsModal(source: string): void {
    this.helperService.openPremiumModal(
      source,
      {
        mobile: 'assets/imgs/sales/sales-findyou-mobile.png',
        pc: 'assets/imgs/sales/sales-findyou-pc.png',
      },
      {
        open: 'openFindYouProTicketsModal',
        close: 'closeFindYouProTicketsModal',
      },
      [
        {
          analytics: {
            event: 'buyFindYouProTickets',
            params: {},
            register: true,
          },
          color: 'secondary',
          fill: 'solid',
          title: 'Adquirir créditos',
          type: 'external',
          path: 'https://jobcity.com.co/find-you/?utm_source=app&utm_medium=app&utm_campaign=modal',
        },
        {
          analytics: {
            event: 'contactFindYouProTickets',
            params: {},
            register: true,
          },
          color: 'light',
          fill: 'outline',
          title: 'Hablar con asesor',
          type: 'external',
          path: `https://wa.me/${environment.salesWhatsapp}?text=¡Deseo averiguar sobre los planes de Find You!`,
        },
      ],
      {
        pc_bottom: '35px',
        pc_left: '65px',
        mobile_bottom: '50px',
        mobile_left: '2px',
      },
    );
  }
}
