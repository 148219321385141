export const environment = {
  production: true,
  test: false,
  firebaseConfig: {
    apiKey: 'AIzaSyCZmQl0bRcnLXzF4w24RX1piAFDDBuN03k',
    authDomain: 'jobcity-prod.firebaseapp.com',
    databaseURL: 'https://jobcity-prod.firebaseio.com',
    projectId: 'jobcity-prod',
    storageBucket: 'jobcity-prod.appspot.com',
    messagingSenderId: '204060833053',
    appId: '1:204060833053:web:10d254a72abe46f67dddf4',
    measurementId: 'G-RZFMDD096L',
  },
  googleCloudVisionAPIKey: 'AIzaSyCXccfMWkWkhG98fqiaHJ6roXGNvjThk1o',
  sentryDsn:
    'https://5f18d5d94be74910b819e7aa230ed804@o494765.ingest.sentry.io/5566390',
  googleAnalyticsTrackingCode: 'UA-145593598-4',
  facebookPixel: '210912043261373',
  baseUrl: 'https://jobcity.web.app/',
  whatsapp: '593993463576',
  salesWhatsapp: '573213576518',
  miguelWhatsapp: '573138135187',
  enriqueWhatsapp: '573227105253',
  politicsLink:
    'https://jobcity.com.co/politica-de-privacidad?utm_source=app&utm_medium=app&utm_campaign=app',
  termsLink:
    'https://jobcity.com.co/terminos-y-condiciones?utm_source=app&utm_medium=app&utm_campaign=app',
  apiUrl: 'https://us-east1-jobcity-prod.cloudfunctions.net/',
  version: 'V 5.8',
};
